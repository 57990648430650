import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['lang']),
  },
  methods: {
    traceLink() {
      if (!this.$store.state.userInfo.userName) {
        if (this.$store.state.portalType == 'Consumer') {
          this.goTroucePage('/register?role=personal')
        } else {
          this.goTroucePage('/register')
        }
        return
      }
      if (this.$store.state.userInfo.utype === 'B') {
        //支撑共建方跳转首页
        this.goTroucePage()
        return
      }
      switch (this.$store.state.userInfo.roletype) {
        case '10':
          this.goTroucePage('/enterpriseHomePage')
          break
        case '20':
          this.goTroucePage('/supervisePage')
          break
        case '30':
          this.goTroucePage('/serverPage')
          break
        case '40':
          this.goTroucePage('/firstPage')
          break
        case '600001':
          this.goTroucePage('/consumerPage')
          break
      }
    },
    traceLinkLogin() {
      if (!this.$store.state.userInfo.userName) {
        if (this.$store.state.portalType == 'Consumer') {
          this.goTroucePage('/loginpass?role=personal')
        } else {
          this.goTroucePage('/loginpass')
        }
        return
      }
    },
  },
}
