export default [
  {
    path: '/intellectual',
    name: 'Intellectual',
    redirect: '/intellectual/home',
    meta: {
      type: 'Intellectual',
      keepAlive: true,
    },
    component: () => import('@/views/Intellectual/index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Intellectual/home.vue'),
      },
      {
        path: 'need',
        name: 'IntellectualNeed',
        component: () => import('@/components/common/Need'),
      },
      {
        path: 'need-details',
        name: 'IntellectualNeedDetails',
        component: () => import('@/components/common/NeedDetails'),
      },
      {
        path: 'ServicePublish',
        name: 'IntellectualServicePublish',
        component: () => import('@/components/common/NeedDetails'),
      },
      {
        path: 'service-provider-info',
        name: 'IntellectualServiceDetails',
        component: () => import('@/components/common/ServiceDetails'),
      },
      {
        path: 'institutions',
        name: 'IntellectualInstitutions',
        component: () => import('@/components/common/Institutions'),
      },
      {
        path: 'search-detail',
        name: 'IntellectualSearchDetail',
        component: () => import('@/components/common/SearchDetail'),
      },
      {
        path: 'help',
        name: 'IntellectualHelp',
        component: () => import('@/components/common/Help'),
      },
      {
        path: 'about',
        name: 'IntellectualAbout',
        component: () => import('@/components/common/About'),
      },
      {
        path: 'traceability',
        name: 'IntellectualTraceability',
        component: () => import('@/components/common/Traceability'),
        children: [
          {
            path: 'articlen-information',
            redirect: '/intellectual/articlen-information',
          },
        ],
      },
      {
        path: 'articlen-information',
        name: 'IntellectualArticleDetails',
        component: () => import('@/components/common/Traceability/Details'),
      },
    ],
  },
]
