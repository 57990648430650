import Vue from 'vue'
import Vuex from 'vuex'
import { getEncryptKey } from '@/api'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    portalType: localStorage.getItem('portalType') || '',
    userInfo: window.localStorage.userInfo ? JSON.parse(window.localStorage.userInfo) : {},
    encryptkey: window.localStorage.encryptkey || '',
    lang: localStorage.getItem('lang') || 'zh',
    loading:false,
  },
  mutations: {
    setPortalType(state, val) {
      state.portalType = val
    },
    setEncryptkey(state, value) {
      window.localStorage.encryptkey = value
      state.encryptkey = value
    },
    setUserInfo(state, val) {
      state.userInfo = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setloginOut(state) {
      window.localStorage.removeItem('userInfo')
      state.userInfo = {}
    },
  },
  actions: {
    getEncryptkey({ commit }) {
      getEncryptKey({ workspc: '' }).then(response => {
        if (response.status === 'success') {
          let temp = JSON.parse(response.data)
          commit('setEncryptkey', temp.publickey)
        } else {
          return
        }
      })
    },
    asProtalType({ commit }, option) {
      commit('setPortalType', option)
    },
    setLoading({commit},loading){
      commit('setLoading',loading)
    },
    loginOut({ commit }) {
      commit('setloginOut')
    },
  },
  modules: {},
  getters:{
    loading:state=>state.loading
  }
})
export default store
