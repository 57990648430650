import axios from 'axios'
import Router from '../router/index'
const api = axios.create({
  baseURL: process.env.VUE_APP_BASEAPI,
})

axios.defaults.withCredentials = true
// request的拦截器对象
api.interceptors.request.use(
  config => {
    config.headers.uiRouteUrl = Router.app._route.path
    config.headers.funcname = encodeURIComponent(Router.app._route.name || '')

    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh_CN'
    lang = lang === 'en' ? 'en' : 'zh_CN'
    config.headers.language = lang

    //加上来源渠道的标记
    config.headers.channel = '60'
    return config
  },
  err => {
    Promise.reject(err)
  }
)

// response的拦截器对象
api.interceptors.response.use(
  res => {
    const status = res.status
    if (status === 200) {
      return Promise.resolve(res.data)
    } else {
      Promise.reject(res)
    }
  },
  err => {
    Promise.reject(err)
  }
)

export default api
