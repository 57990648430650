export default [
  {
    path: '/consumer',
    name: 'Consumer',
    redirect: '/consumer/home',
    component: () => import('@/views/Consumer/index.vue'),
    meta: {
      type: 'Consumer',
      keepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Consumer/home.vue'),
      },
      {
        path: 'customer-service',
        name: 'CustomerService',
        component: () => import('@/views/Consumer/CustomerService'),
      },
      {
        path: 'search-detail',
        name: 'CustomerSearchDetail',
        component: () => import('@/components/common/SearchDetail'),
      },
      {
        path: 'sourceQueryList',
        name: 'SourceQueryList',
        component: () => import('@/components/common/SourceQueryList'),
      },
      {
        path: 'help',
        name: 'ConsumerHelp',
        component: () => import('@/components/common/Help'),
      },
      {
        path: 'about',
        name: 'ConsumerAbout',
        component: () => import('@/components/common/About'),
      },
      {
        path: 'traceability',
        name: 'ConsumerTraceability',
        component: () => import('@/components/common/Traceability'),
        children: [
          {
            path: 'articlen-information',
            redirect: '/consumer/articlen-information',
          },
        ],
      },
      {
        path: 'articlen-information',
        name: 'ConsumerArticleDetails',
        component: () => import('@/components/common/Traceability/Details'),
      },
    ],
  },
]
