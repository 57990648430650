import api from '@/utils/interceptor'

//登录
export const login = params => {
  return api.get('/access/ui/FRONT-COMM/do/GTCPSPLOGIN040', params)
}
//登录获取头像
export const getUserImage = params => {
  return api.get('/access/ui/FRONT-COMM/do/GTCPSPUSER070')
}

//退出登录
export const loginOut = params => {
  return api.get('/access/ui/GTCPSP-FRONT/do/GTCPSPLOGIN020')
}


//获取公钥
export const getEncryptKey = params => {
  return api.post('/access/ui/FRONT-COMM/FRONTCOMMCRYPT010', params)
}

// 获取聊天客户端页面地址
export const getOnlineChatUrl = systemType => {
  return api.post('/access/ui/FRONT-COMM/GTCPSPCMSARTICLESERVICE004', {
    siteid: 'webportals',
    catalogid: `${systemType}Robot`,
  })
}

// 帮助中心模糊检索
export const helpCenterSearch = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE004', params)
}
// 帮助中心模糊检索
export const helpCenterSearchS = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE013', params)
}
// 数源查询
export const querySource = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPOGCODEDATA130', params)
}

// 查询服务商信息
export const getServiceProvider = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPSERVRELEASE025', params)
}

// 查询服务商详情信息
export const getServiceProviderDetails = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCBMINFONOLOGIN102', params)
}

// 查询需求一览
export const getDemand = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPDESIREDREG024', params)
}
// 查询需求一览详情
export const getDemandDetails = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPDESIREDREG025', params)
}
// 查询服务一览
export const getService = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPSERVRELEASE021', params)
}
// 查询服务一览详情
export const getServiceDetails = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPSERVRELEASE024', params)
}

// 首页轮播图
export const homeBanner = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE006', params)
}

// 数源资讯
export const getInformation = catalogid => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE004', {
    siteid: 'webportals',
    pageParam: { firstRow: 0, pageNo: 1, rowsPerPage: 3 },
    catalogid,
  })
}
// 背景图
export const bannerList = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE004', {
    siteid: 'webportals',
    pageParam: { firstRow: 0, pageNo: 1, rowsPerPage: 1 },
    ...params
  })
}
// 背景图
export const bannerListTwo = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE005', {
    ...params
  })
}

// 数源应用案例
export const getApplicationCase = catalogid => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCMSARTICLESERVICE004', {
    siteid: 'webportals',
    pageParam: { firstRow: 0, pageNo: 1, rowsPerPage: 10 },
    catalogid,
  })
}

// 最新发布的需求 - 以企业为单位显示
export const releaseRequirements = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPDESIREDREG029', params)
}

// 最新发布的服务 - 以企业为单位显示
export const releaseServices = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPSERVRELEASE029', params)
}

// 企业编码查对应的服务范围
export const getEnterprise = params => {
  return api.post('/access/ui/GTCPSP-FRONT/GTCPSPCCODEDATA040', params)
}
   //关键词查询
   export const getKeyWord=params=> {
    return api.post('access/ui/FRONT-COMM/GTCPSPCMSARTICLESERVICE012', params);
}
 //是否推荐
 export const getRecommendflag=params=> {
    return api.post('access/ui/FRONT-COMM/GTCPSPCMSARTICLESERVICE011', params);
}