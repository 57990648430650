import Vue from 'vue'
import store from './store'

export const host = process.env.NODE_ENV === 'development' ? 'http://10.10.104.3' : ''

import { formatDate } from '@/utils/utils'

import config from '@/p.config'

Vue.prototype.$host = host

Vue.prototype.$goRegister = () => {
  if (window.location.host !== 'www.global-suyuan.com') {
    if (process.env.NODE_ENV === 'development') {
      window.open(`http://10.10.104.3/#/register`)
    } else {
      window.open(`${window.location.origin}/#/register`)
    }
  } else {
    window.open(`https://home.global-suyuan.com/#/register`)
  }
}

Vue.prototype.$goLogin = () => {
  if (window.location.host !== 'www.global-suyuan.com') {
    if (process.env.NODE_ENV === 'development') {
      window.open(`http://10.10.104.3/#/loginpass`)
    } else {
      window.open(`${window.location.origin}/#/loginpass`)
    }
  } else {
    window.open(`https://home.global-suyuan.com/#/loginpass`)
  }
}

Vue.prototype.goTroucePage = pageUrl => {
  let localurl = window.location.href
  let authurl = 'authurl=' + encodeURIComponent(localurl)
  let host = window.location.origin
  let arr = ['10.10.104.3', '10.10.104.60', 'test.global-suyuan']
  if (arr.every(item => window.location.origin.indexOf(item) == -1)) {
    host = 'https://home.global-suyuan.com'
  }
  if (process.env.NODE_ENV === 'development') {
    host = config.proxy_url
  }
  let jumpUrl = host
  let path = '/#'
  if (store.state.userInfo.utype === 'B') {
    path = '/admin/#'
  }
  jumpUrl = host + path
  if (pageUrl) {
    jumpUrl += pageUrl
  }
  window.open(jumpUrl)
}

Vue.prototype.formatDate = function (value, formatStr) {
  return formatDate(value, formatStr)
}

//显示loading
Vue.prototype.showLoading = function () {
  this.$store.commit('setLoading',true)
}
//隐藏loading
Vue.prototype.hideLoading = function () {
  this.$store.commit('setLoading',false)
}
