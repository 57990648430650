<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  watch: {
    lang: {
      handler(nv) {
        const langType = {
          zh: 'language_zh',
          en: 'language_en',
        }
        document.getElementsByTagName('body')[0].classList.add(langType[nv || 'zh'])
      },
      immediate: true,
    },
  },
  created() {
    if (!window.localStorage.encryptkey) {
      this.$store.dispatch('getEncryptkey')
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/font/iconfont.css';
@import '@/assets/styles/index.scss';

#app {
  color: #2c3e50;
  font-family: Noto Sans SC;
  font-weight: 300;
  font-size: $font-14;
  word-break: break-all;
  position: relative;
}
</style>
