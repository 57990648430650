export const pageTitle = {
  CenterMain: {
    title: 'Global_Traceability_Center_Public_Service_Platform_2',
  },
  Inspection: {
    title: 'Public_Service_Platform_for_Verification_and_Examination',
  },
  Intellectual: {
    title: 'Public_Service_Platform_for_Intellectual_Property_Protection',
  },
  Industry: {
    title: 'Public_Service_Platform_for_Traceability_Industry',
  },
  Consumer: {
    title: 'Public_Service_Platform_for_Consumer_Rights_Protection',
  },
}
