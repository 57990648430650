import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// 中心公服
import centerMain from './Centermain'
// 检验检测
import inspection from './Inspection'
// 知识产权
import intellectual from './Intellectual'
// 数源产业
import industry from './Industry'
// 消费者权益
import consumer from './Consumer'
const routes = [
  {
    path: '/',
    meta: { keepAlive: true },
    redirect: '/centerMain',
  },
  ...centerMain,
  ...inspection,
  ...intellectual,
  ...industry,
  ...consumer,
]

export default new Router({
  mode: 'history',
  // base: process.env.NODE_ENV === 'development' ? '' : '/portal/',
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition
    }
    // 异步滚动操作
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 0)
    })
  },
  routes,
})
