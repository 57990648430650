function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}
export const formatDate = function (date, format = 'YYYY-MM-DD') {
  if (!date) {
    return
  }
  if (date && !isNaN(date)) {
    date = +date
  }
  if (typeof date === 'string') {
    date = date.replace(/\-/g, '/')
  }
  date = new Date(date)
  if (/(y+)/.test(format) || /(Y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let obj = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'D+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in obj) {
    if (new RegExp(`(${k})`).test(format)) {
      let str = obj[k] + ''
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return format
}

export const downloadFile = url => {
  /*downloadmode=1时，图片、文本等类型的文件不会进行下载，改为2就可以了*/
  url = url.replace('downloadmode=1', 'downloadmode=2')
  function downloadinner(fileUrl) {
    let iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = fileUrl
    document.body.appendChild(iframe)
  }
  if (navigator.userAgent.indexOf('Firefox') > 0) {
    downloadinner(url)
  } else {
    let a = document.createElement('a')
    a.href = url
    a.download = getDownLoadFileName(url)
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  }
}
function getDownLoadFileName(url) {
  let arrUrl = url.split('/')
  let strPage = arrUrl[arrUrl.length - 1]
  strPage = strPage.split('?')[0]
  if (strPage.indexOf('/#/') != -1) {
    strPage = strPage.replace('/#/', '?')
    strPage = strPage.split('?')[0]
  }
  return strPage
}

export const getOffset = e => {
  let offsetTop = e.offsetTop,
    offsetLeft = e.offsetLeft
  if (e.offsetParent != null) {
    offsetTop += getOffset(e.offsetParent).offsetTop
    offsetLeft += getOffset(e.offsetParent).offsetLeft
  }
  return {
    offsetTop,
    offsetLeft,
  }
}
