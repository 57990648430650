module.exports = {
  proxy_url: 'http://10.10.104.3:80', // 测试
  // proxy_url: 'http://10.10.104.170', //联调
  // proxy_url: 'http://10.10.104.60', //预生产
  // proxy_url: 'https://www.global-suyuan.com', // 小程序线上
  apphead: '/fbacs/',
  //图片前缀地址
  base_image_url: '/fbacs/',
  //视频上传地址
  videoUploadUrl: '/fbacs/gtcommtool/do/commtoolvideo',
}
