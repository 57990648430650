import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import './lib/element-ui'
import i18n from './lib/i18n/index'
import store from './store'
import { pageTitle } from '@/constant/RouterData'
import './global'
import { login, getUserImage } from '@/api/index'

import globalMixin from '@/mixins/globalMixin'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)

Vue.config.productionTip = false
Vue.mixin(globalMixin)

function isMobile() {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

router.beforeEach(async (to, from, next) => {
  if (isMobile() && !window.location.pathname.includes('portalapp')) {
    //如果是移动端跳转到移动端项目
    let portalType = ['centerMain', 'Inspection', 'intellectual', 'industry', 'consumer']
    for (let index = 0; index < portalType.length; index++) {
      const item = portalType[index]
      if (window.location.pathname.includes(item)) {
        window.location.replace(`${window.location.origin}/portalapp/${item}/home`)
        break
      } else {
        if (index === portalType.length - 1) {
          window.location.replace(`${window.location.origin}/portalapp/centerMain/home`)
        }
      }
    }
  }
  if (to.query.RSESSIONID) {
    //需要登录
    document.cookie = `RSESSIONID=${to.query.RSESSIONID};path=/`
    document.cookie = `RSESSIONID=${to.query.RSESSIONID};path=/fbacs`

    //getUserImage 为了获取用户头像
    Promise.all([login(), getUserImage()]).then(res => {
      if (res[0].data) {
        res[1].data.imagepath ? (res[0].data.imagepath = res[1].data.imagepath) : ''
        window.localStorage.setItem('userInfo', JSON.stringify(res[0].data))
      } else {
        window.localStorage.setItem('userInfo', '{}')
      }
      store.commit('setUserInfo', res[0].data || {})
    })
  }
  if (to.meta.type) {
    localStorage.setItem('portalType', to.meta.type)
    store.dispatch('asProtalType', to.meta.type)
  } else if (to.matched.length && to.matched[0].meta.type) {
    localStorage.setItem('portalType', to.matched[0].meta.type)
    store.dispatch('asProtalType', to.matched[0].meta.type)
  }
  if (to.query.RSESSIONID) {
    delete to.query.RSESSIONID
    next({ path: to.path, query: to.query })
  } else {
    next()
  }
})

router.afterEach(to => {
  const { type = '', title } = to.meta || {}
  if (title) {
    document.title = title
  } else if (type) {
    document.title = i18n.t(pageTitle[type].title)
  } else if (to.matched.length && to.matched[0].meta.type) {
    document.title = i18n.t(pageTitle[to.matched[0].meta.type].title)
  }
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
