export default [
  {
    path: '/industry',
    name: 'Industry',
    redirect: '/industry/home',
    component: () => import('@/views/Industry/index.vue'),
    meta: {
      type: 'Industry',
      keepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Industry/home.vue'),
      },
      {
        path: 'need',
        name: 'IndustryNeed',
        component: () => import('@/components/common/Need'),
      },
      {
        path: 'need-details',
        name: 'IndustryNeedDetails',
        component: () => import('@/components/common/NeedDetails'),
      },
      {
        path: 'ServicePublish',
        name: 'IndustryServicePublish',
        component: () => import('@/components/common/NeedDetails'),
      },
      {
        path: 'service-provider-info',
        name: 'IndustryServiceDetails',
        component: () => import('@/components/common/ServiceDetails'),
      },
      {
        path: 'institutions',
        name: 'IndustryInstitutions',
        component: () => import('@/components/common/Institutions'),
      },
      {
        path: 'search-detail',
        name: 'IndustrySearchDetail',
        component: () => import('@/components/common/SearchDetail'),
      },
      {
        path: 'help',
        name: 'IndustryHelp',
        component: () => import('@/components/common/Help'),
      },
      {
        path: 'about',
        name: 'IndustryAbout',
        component: () => import('@/components/common/About'),
      },
      {
        path: 'traceability',
        name: 'IndustryTraceability',
        component: () => import('@/components/common/Traceability'),
        children: [
          {
            path: 'articlen-information',
            redirect: '/industry/articlen-information',
          },
        ],
      },
      {
        path: 'articlen-information',
        name: 'IndustryArticleDetails',
        component: () => import('@/components/common/Traceability/Details'),
      },
      {
        path: 'senceSevr',
        name: 'senceSevr',
        component: () => import('@/views/Industry/senceSevr'),
      },
    ],
  },
]
