export default [
  {
    path: '/centerMain',
    meta: { type: 'CenterMain', keepAlive: true },
    redirect: '/centerMain/home',
    component: () => import('../views/CenterMain/index.vue'),
    children: [
      {
        path: 'home',
        component: () => import('../views/CenterMain/home.vue'),
      },
      {
        path: 'sourceQueryList',
        name: 'CenterMainSourceQueryList',
        component: () => import('@/components/common/SourceQueryList'),
      },
      {
        path: 'search-detail',
        name: 'CenterMainSearchDetail',
        component: () => import('@/components/common/SearchDetail'),
      },
      {
        path: 'help',
        name: 'CenterMainHelp',
        component: () => import('@/components/common/Help'),
      },
      {
        path: 'about',
        name: 'CenterMainAbout',
        component: () => import('@/components/common/About'),
      },
      {
        path: 'Consumer',
        name: 'Consumer',
        component: () => import('../views/CenterMain/Consumer.vue'),
      },
      {
        path: 'CoBuilder',
        name: 'CoBuilder',
        component: () => import('../views/CenterMain/CoBuilder.vue'),
      },
      {
        path: 'Standard',
        name: 'Standard',
        component: () => import('../views/CenterMain/Standard.vue'),
      },
      {
        path: 'traceability',
        name: 'CenterMainTraceability',
        component: () => import('@/components/common/Traceability'),
        children: [
          {
            path: 'articlen-information',
            redirect: '/centerMain/articlen-information',
          },
        ],
      },
      {
        path: 'articlen-information',
        name: 'CenterMainArticleDetails',
        component: () => import('@/components/common/Traceability/Details'),
      },
      {
        path: 'Scence',
        name: 'Scence',
        component: () => import('@/views/CenterMain/Scence'),
      },
    ],
  },
]
